<template>
  <div class="home">
    <div class="home_header">
      <div class="head_left">
        <!--  订单总数  -->
        <div class="orderTotalNumber" v-loading="orderTotalNumber">
          <order-total-number :loading.sync="orderTotalNumber" />
        </div>
        <!--  交易金额  -->
        <div class="tradeAmount" v-loading="tradeAmountLoading">
          <trade-amount :loading.sync="tradeAmountLoading" />
        </div>
        <!--  累计用户数  -->
        <div class="users" v-loading="usersLoading">
          <users :loading.sync="usersLoading" />
        </div>
        <!--  服务单数  -->
        <div class="serveOrder" v-loading="serveOrderLoading">
          <serve-order :loading.sync="serveOrderLoading" />
        </div>
        <!--  进账金额  -->
        <div class="payoutAmount" v-loading="payoutAmountLoading">
          <payout-amount :loading.sync="payoutAmountLoading" />
        </div>
        <div class="viewers" v-loading="viewersLoading">
          <viewers :loading.sync="viewersLoading" />
        </div>
      </div>
      <div class="head_right ">
        <!--    公司信息    -->
        <company-info />
      </div>
    </div>
    <div class="home_footer">
        <general-chart />
    </div>
  </div>
</template>

<script>
const orderTotalNumber = () => import('./orderTotalNumber/index.vue')
const tradeAmount = () => import('./tradeAmount/index.vue')
const users = () => import('./users/index.vue')
const serveOrder = () => import('./serveOrder/index.vue')
const payoutAmount = () => import('./payoutAmount/index.vue')
const viewers = () => import('./viewers/index.vue')
const companyInfo = () => import('./companyInfo/index.vue')
const generalChart = () => import('./generalChart/index.vue')
export default {
  components:{
    orderTotalNumber,
    tradeAmount,
    users,
    serveOrder,
    payoutAmount,
    viewers,
    companyInfo,
    generalChart
  },
  data(){
    return {
      orderTotalNumber:true,
      tradeAmountLoading:true,
      usersLoading:true,
      serveOrderLoading:true,
      payoutAmountLoading:true,
      viewersLoading:true,
    }
  },


}
</script>

<style lang="scss" scoped>
.home{
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.home_header{
  display: flex;
  margin-bottom: 20px;
  width: 100%;
}

.head_left{
  width: 75%;
  display: grid;
  grid-template-columns: repeat(3, minmax(75px, 1fr));
  grid-gap: 20px;
  box-sizing: border-box;

  .orderTotalNumber,
  .tradeAmount,
  .users,
  .serveOrder,
  .payoutAmount,
  .viewers{
    height: 180px;
    background: #fff;
    padding: 12px;
    box-sizing: border-box;
  }
}
.head_right {
  flex: 1;
  background: #fff;
  margin-left: 20px;
}
.home_footer{
  flex: 1;
  height: 100%;
}

</style>